import React, { useState, useEffect } from "react";
import _ from "lodash";
import { navigate } from "@reach/router";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton } from "../../common/Buttons";
import ActionBar from "../../common/ActionBar";
import AboutTermsCard from "../../common/AboutTermsCard";
import UpdatePhone from "./common/UpdatePhone";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import SystemService from "../../../services/SystemService";
import UpdateVideo from "./common/UpdateVideo";
import UpdateMediationTemplates from "./common/UpdateMediationTemplates";
import VideoPlayer from "../../common/Video";
import labels from "../../../helpers/labels.json";
import UpdateLogo from "./common/UpdateLogo";
import useSettings from "../../../hooks/useSettings";
import UpdateRules from "./common/UpdateRules";
import { Images } from "../../../assets/images";
import FAQ from "./common/FAQ";
import TermsAndCondition from "./common/TermsAndCondition";
import UpdateFavIcon from "./common/UpdateFavIcon";
import PrivacyPolicy from "./common/PrivacyPolicy";
import MediatorsInfo from "./common/MediatorsInfo";
import ArbitratorsInfo from "./common/ArbitratorsInfo";
import UpdateArbitrationTemplates from "./common/UpdateArbitrationTemplates";
import UpdateBulkUploadNegotiation from "./common/UpdateBulkUploadNegotiation";
import UpdateBulkUploadMediation from "./common/UpdateBulkUploadMediation";
import UpdateBulkUploadArbitration from "./common/UpdateBulkUploadArbitration";
import UpdateMobileOtpConfig from "./common/MobileOtpConfig";
import PartyVerification from "./common/PartyVerification";
import CounterClaim from "./common/CounterClaim";

const Index = () => {
  const breadcrumbs = [_.startCase(labels.configurations)];
  const [modal, setModal] = useState();
  const [updateLogoModal, setUpdateLogoModal] = useState(false);
  const [updateRulesModal, setUpdateRulesModal] = useState(false);
  const [videoModal, setVideoModal] = useState();
  const [mediationTemplateModal, setMediationTemplateModal] = useState();
  const [updateFavIconModal, setFavIconModal] = useState(false);
  const [arbitrationTemplateModal, setArbitrationTemplateModal] = useState();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { setting, setSetting } = useSettings();
  const [updateBulkUploadNeg, setUpdateBulkUploadNeg] = useState(false);
  const [updateBulkUploadMed, setUpdateBulkUploadMed] = useState(false);
  const [updateBulkUploadArb, setUpdateBulkUploadArb] = useState(false);
  const [systemConfig, setSystemConfig] = useState({
    mobile: "",
    tutorialUrl: [],
    faq: [],
    terminology: [],
    mediationTemplateUrl: [],
    arbitrationTemplateUrl: [],
    mediatorsInfo: [],
    arbitratorsInfo: [],
  });
  const [mobModal, setMobModal] = useState(false);

  useEffect(() => {
    async function getSystemConfig() {
      try {
        setLoader({ state: true, message: "Fetching Configurations..." });
        const response = await SystemService.getConfigAuth();
        response.systemConfig.tutorialUrl =
          response.systemConfig.tutorialUrl.split(",");
        response.systemConfig.mediationTemplateUrl =
          response.systemConfig.mediationTemplateUrl.split(",");
        response.systemConfig.arbitrationTemplateUrl =
          response.systemConfig.arbitrationTemplateUrl.split(",");
        if (response.systemConfig) {
          setSystemConfig(response.systemConfig);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFileName = (tutorialUrl) => {
    if (tutorialUrl) {
      const fileName = decodeURI(tutorialUrl);
      return fileName.length < 30
        ? fileName
        : fileName
          .substring(tutorialUrl.lastIndexOf("/") + 1 || tutorialUrl)
          .split("/")
          .slice(-1)
          .join()
          .split(".")
          .shift();
    } else {
      return "";
    }
  };

  // const setFileNameAgreement = (settlementAgreementUrl) => {
  //   if (settlementAgreementUrl) {
  //     const fileName = decodeURI(settlementAgreementUrl);
  //     return fileName.length < 30
  //       ? fileName
  //       : fileName
  //           .substring(settlementAgreementUrl.lastIndexOf("/") + 1 || settlementAgreementUrl)
  //           .split("/")
  //           .slice(-1)
  //           .join()
  //           .split(".")
  //           .shift();
  //   } else {
  //     return "";
  //   }
  // };

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.configurations:
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };

  async function handleSystemConfigChange(payload, callback = () => { }) {
    try {
      setLoader({ state: true, message: "Updating Configuration..." });
      payload.tutorialUrl = _.join(payload.tutorialUrl, ",");
      payload.mediationTemplateUrl = _.join(payload.mediationTemplateUrl, ",");
      payload.arbitrationTemplateUrl = _.join(
        payload.arbitrationTemplateUrl,
        ",",
      );
      const response = await SystemService.setConfig(payload);
      if (response.config) {
        let {
          mobile,
          tutorialUrl,
          faq,
          terminology,
          logoUrl,
          rulesDocumentUrl,
          mediationTemplateUrl,
          arbitrationTemplateUrl,
          mediatorsInfo,
          arbitratorsInfo,
          faviconUrl,
          bulkUploadNegTemplateUrl,
          bulkUploadMedTemplateUrl,
          bulkUploadArbTemplateUrl,
          noOfOtpAttempts,
          timeIntervalForOtpAttempts,
          partyVerificationMethod,
          counterClaimDeadline,
        } = response.config;
        tutorialUrl = tutorialUrl.split(",");
        mediationTemplateUrl = mediationTemplateUrl
          ? mediationTemplateUrl.split(",")
          : "";
        arbitrationTemplateUrl = arbitrationTemplateUrl
          ? arbitrationTemplateUrl.split(",")
          : "";
        setSystemConfig({
          mobile,
          tutorialUrl,
          faq,
          terminology,
          logoUrl,
          rulesDocumentUrl,
          mediationTemplateUrl,
          arbitrationTemplateUrl,
          mediatorsInfo,
          arbitratorsInfo,
          faviconUrl,
          bulkUploadNegTemplateUrl,
          bulkUploadMedTemplateUrl,
          bulkUploadArbTemplateUrl,
          noOfOtpAttempts,
          timeIntervalForOtpAttempts,
          partyVerificationMethod,
          counterClaimDeadline,
        });
        setSetting({
          ...setting,
          ...{
            mobile,
            tutorialUrl,
            faq,
            terminology,
            logoUrl,
            rulesDocumentUrl,
            mediationTemplateUrl,
            arbitrationTemplateUrl,
            mediatorsInfo,
            arbitratorsInfo,
            faviconUrl,
            bulkUploadNegTemplateUrl,
            bulkUploadMedTemplateUrl,
            bulkUploadArbTemplateUrl,
            noOfOtpAttempts,
            timeIntervalForOtpAttempts,
            partyVerificationMethod,
            counterClaimDeadline,
          },
        });
        callback();
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const tutorialUrl =
    typeof systemConfig.tutorialUrl === "string"
      ? [systemConfig.tutorialUrl]
      : systemConfig.tutorialUrl;

  const mediationTemplateUrl =
    typeof systemConfig.mediationTemplateUrl === "string"
      ? [systemConfig.mediationTemplateUrl]
      : systemConfig.mediationTemplateUrl;

  const arbitrationTemplateUrl =
    typeof systemConfig.arbitrationTemplateUrl === "string"
      ? [systemConfig.arbitrationTemplateUrl]
      : systemConfig.arbitrationTemplateUrl;

  async function updateRecordInMIS() {
    try {
      setLoader({ state: true, message: "update" });
      const response = await SystemService.updateRecordInMIS();
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (err) {
      enqueueSnackbar(err?.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <WrapperRow>
          <WrapperVideo>
            <FlexBetween>
              <H2>{labels.tutorial_video}</H2>
              <WrapperButton onClick={() => setVideoModal(true)}>
                <PrimaryCTAButton style={{ padding: "unset", height: 35 }}>
                  {labels.update}
                </PrimaryCTAButton>
              </WrapperButton>
            </FlexBetween>
            {tutorialUrl.map((tutorialUrl, index) => (
              <div key={tutorialUrl + index}>
                <H2 style={{ margin: "15px", color: COLORS.BTN_GREEN }}>
                  {" "}
                  {setFileName(tutorialUrl)}
                </H2>
                <VideoPlayer src={tutorialUrl} />
              </div>
            ))}
          </WrapperVideo>
          <div>
            <div>
              <WrapperNumber>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.call_center}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setModal(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <TextXl>{systemConfig?.mobile}</TextXl>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.logo_platform}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setUpdateLogoModal(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <div className="logo">
                  <LogoUrl
                    src={systemConfig?.logoUrl || Images.companyLogo}
                  ></LogoUrl>
                </div>
              </WrapperNumber>
            </div>
            {/* <!-- Rules upload container --> */}
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.rules}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setUpdateRulesModal(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <div
                  title={getFileName(
                    systemConfig?.rulesDocumentUrl ||
                    "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/13/1620883614227-f4dbb5a2-4d9c-4a80-8645-b3500816dead/JustAct_Rules.pdf",
                  )}
                  className="rules"
                  onClick={() =>
                    window.open(
                      systemConfig?.rulesDocumentUrl ||
                      "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/13/1620883614227-f4dbb5a2-4d9c-4a80-8645-b3500816dead/JustAct_Rules.pdf",
                      "_blank",
                    )
                  }
                >
                  {getFileName(
                    systemConfig?.rulesDocumentUrl ||
                    "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/13/1620883614227-f4dbb5a2-4d9c-4a80-8645-b3500816dead/JustAct_Rules.pdf",
                  )}
                </div>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.mediation_templates}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setMediationTemplateModal(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                {mediationTemplateUrl.map((mediationTemplateUrl, index) => (
                  <div key={mediationTemplateUrl + index}>
                    <H2
                      style={{
                        margin: "15px",
                        color: COLORS.BTN_GREEN,
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(mediationTemplateUrl)}
                    >
                      {" "}
                      {mediationTemplateUrl?.split("/").length > 0
                        ? decodeURI(
                          mediationTemplateUrl?.split("/")[
                          mediationTemplateUrl?.split("/").length - 1
                          ],
                        )
                        : "-"}
                    </H2>
                    {/* <VideoPlayer src={settlementAgreementUrl} /> */}
                  </div>
                ))}
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.arbitration_templates}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setArbitrationTemplateModal(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                {arbitrationTemplateUrl
                  ? arbitrationTemplateUrl.map(
                    (arbitrationTemplateUrl, index) => (
                      <div key={arbitrationTemplateUrl + index}>
                        <H2
                          style={{
                            margin: "15px",
                            color: COLORS.BTN_GREEN,
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(arbitrationTemplateUrl)}
                        >
                          {" "}
                          {/* {AgreementName(arbitrationTemplateUrl)} */}
                          {arbitrationTemplateUrl?.split("/").length > 0
                            ? decodeURI(
                              arbitrationTemplateUrl?.split("/")[
                              arbitrationTemplateUrl?.split("/").length -
                              1
                              ],
                            )
                            : "-"}
                        </H2>
                      </div>
                    ),
                  )
                  : ""}
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.logo_favicon}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setFavIconModal(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <div>
                  <LogoUrl src={systemConfig?.faviconUrl}></LogoUrl>
                </div>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.bulkupload_negotiation}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setUpdateBulkUploadNeg(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <div>
                  <H2
                    style={{
                      margin: "15px",
                      color: COLORS.BTN_GREEN,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(systemConfig?.bulkUploadNegTemplateUrl)
                    }
                  >
                    {" "}
                    {/* {AgreementName(arbitrationTemplateUrl)} */}
                    {systemConfig?.bulkUploadNegTemplateUrl?.split("/").length >
                      0
                      ? decodeURI(
                        systemConfig?.bulkUploadNegTemplateUrl?.split("/")[
                        systemConfig?.bulkUploadNegTemplateUrl?.split("/")
                          .length - 1
                        ],
                      )
                      : "-"}
                  </H2>
                </div>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.bulkupload_mediation}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setUpdateBulkUploadMed(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <div>
                  <H2
                    style={{
                      margin: "15px",
                      color: COLORS.BTN_GREEN,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(systemConfig?.bulkUploadMedTemplateUrl)
                    }
                  >
                    {" "}
                    {/* {AgreementName(arbitrationTemplateUrl)} */}
                    {systemConfig?.bulkUploadMedTemplateUrl?.split("/").length >
                      0
                      ? decodeURI(
                        systemConfig?.bulkUploadMedTemplateUrl?.split("/")[
                        systemConfig?.bulkUploadMedTemplateUrl?.split("/")
                          .length - 1
                        ],
                      )
                      : "-"}
                  </H2>
                </div>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{labels.bulkupload_arbitration}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setUpdateBulkUploadArb(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <div>
                  <H2
                    style={{
                      margin: "15px",
                      color: COLORS.BTN_GREEN,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(systemConfig?.bulkUploadArbTemplateUrl)
                    }
                  >
                    {" "}
                    {/* {AgreementName(arbitrationTemplateUrl)} */}
                    {systemConfig?.bulkUploadArbTemplateUrl?.split("/").length >
                      0
                      ? decodeURI(
                        systemConfig?.bulkUploadArbTemplateUrl?.split("/")[
                        systemConfig?.bulkUploadArbTemplateUrl?.split("/")
                          .length - 1
                        ],
                      )
                      : "-"}
                  </H2>
                </div>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{"Otp Login Configuration"}</H2>
                  <WrapperButton>
                    <PrimaryCTAButton
                      onClick={() => setMobModal(true)}
                      style={{ padding: "unset", height: 35 }}
                    >
                      {labels.update}
                    </PrimaryCTAButton>
                  </WrapperButton>
                </FlexBetween>
                <div>
                  <H2
                    style={{
                      margin: "15px",
                      color: COLORS.BTN_GREEN,
                      cursor: "pointer",
                    }}
                  >
                    {systemConfig.noOfOtpAttempts}
                  </H2>
                </div>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{"Party Verification Configuration"}</H2>
                </FlexBetween>
                <PartyVerification
                  {...{ systemConfig, handleSystemConfigChange }}
                />
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{"Counter Claim Configuration"}</H2>
                </FlexBetween>
                <CounterClaim {...{ systemConfig, handleSystemConfigChange }} />
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{"Case Stages"}</H2>
                  <PrimaryCTAButton
                    onClick={() =>
                      navigate(`/dashboard/configurations/stages-info`)
                    }
                    style={{ width: "30%", height: 35 }}
                  >
                    Update
                  </PrimaryCTAButton>
                </FlexBetween>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{"Document Types"}</H2>
                  <PrimaryCTAButton
                    onClick={() =>
                      navigate(`/dashboard/configurations/documents-info`)
                    }
                    style={{ width: "30%", height: 35 }}
                  >
                    Update
                  </PrimaryCTAButton>
                </FlexBetween>
              </WrapperNumber>
            </div>
            <div className="container">
              <WrapperNumber style={{ height: "auto" }}>
                <FlexBetween
                  style={{
                    width: 380,
                    height: "auto",
                    marginBottom: "unset",
                  }}
                >
                  <H2>{"MIS Dashboard"}</H2>
                  <PrimaryCTAButton
                    onClick={updateRecordInMIS}
                    style={{ width: "30%", height: 35 }}
                  >
                    Update
                  </PrimaryCTAButton>
                </FlexBetween>
              </WrapperNumber>
            </div>
            {/* <!-- Rules upload container End--> */}
          </div>
          <UpdatePhone
            {...{ modal, setModal, systemConfig, handleSystemConfigChange }}
          />

          <UpdateLogo
            {...{ systemConfig, handleSystemConfigChange }}
            setModal={setUpdateLogoModal}
            modal={updateLogoModal}
          />

          <UpdateRules
            {...{ systemConfig, handleSystemConfigChange }}
            setModal={setUpdateRulesModal}
            modal={updateRulesModal}
          />

          <UpdateVideo
            modal={videoModal}
            setModal={setVideoModal}
            {...{ systemConfig, handleSystemConfigChange }}
          />

          <UpdateMediationTemplates
            modal={mediationTemplateModal}
            setModal={setMediationTemplateModal}
            {...{ systemConfig, handleSystemConfigChange }}
          />

          <UpdateArbitrationTemplates
            modal={arbitrationTemplateModal}
            setModal={setArbitrationTemplateModal}
            {...{ systemConfig, handleSystemConfigChange }}
          />

          <UpdateFavIcon
            {...{ systemConfig, handleSystemConfigChange }}
            setModal={setFavIconModal}
            modal={updateFavIconModal}
          />

          <UpdateBulkUploadNegotiation
            {...{ systemConfig, handleSystemConfigChange }}
            setModal={setUpdateBulkUploadNeg}
            modal={updateBulkUploadNeg}
          />

          <UpdateBulkUploadMediation
            {...{ systemConfig, handleSystemConfigChange }}
            setModal={setUpdateBulkUploadMed}
            modal={updateBulkUploadMed}
          />

          <UpdateBulkUploadArbitration
            {...{ systemConfig, handleSystemConfigChange }}
            setModal={setUpdateBulkUploadArb}
            modal={updateBulkUploadArb}
          />

          <UpdateMobileOtpConfig
            {...{
              systemConfig,
              handleSystemConfigChange,
            }}
            modal={mobModal}
            setModal={setMobModal}
          />
        </WrapperRow>

        <AboutTermsCard
          StyleCard={{
            marginBottom: 20,
            marginTop: 50,
            padding: "0px",
            width: "98%",
          }}
          StyledPara={{
            height: "unset",
            marginBottom: 10,
          }}
          terms={systemConfig.terminology}
          heading={labels.terms_heading}
          button={labels.update}
          buttonOnClick={() => navigate("/dashboard/configurations/terms")}
        />
        <TermsAndCondition
          StyleCard={{
            marginBottom: 20,
            marginTop: 20,
            padding: "0px",
            width: "98%",
          }}
          {...{ systemConfig }}
        />

        <PrivacyPolicy
          StyleCard={{
            marginBottom: 20,
            marginTop: 20,
            padding: "0px",
            width: "98%",
          }}
          {...{ systemConfig }}
        />
        <FAQ
          StyleCard={{
            marginBottom: 20,
            marginTop: 20,
            padding: "0px",
            width: "98%",
          }}
          {...{ systemConfig }}
        />

        <MediatorsInfo
          StyleCard={{
            marginBottom: 20,
            marginTop: 20,
            padding: "0px",
            width: "98%",
          }}
          {...{ systemConfig }}
        />

        <ArbitratorsInfo
          StyleCard={{
            marginBottom: 20,
            marginTop: 20,
            padding: "0px",
            width: "98%",
          }}
          {...{ systemConfig }}
        />
      </Container>
    </>
  );
};

export default Index;

const Container = styled.div`
  padding: 42px 46px;
  display: flex;
  flex-direction: column;
  .container {
    margin-top: 20px;
    border: 1px solid ${COLORS.INPUT_BORDER};
    border-radius: 5px;
    .logo {
      padding-top: 15px;
    }
    .rules {
      color: ${COLORS.BTN_GREEN};
      font-family: ${theme.fonts.primaryFontSemiBold};
      cursor: pointer;
      margin-top: 20px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .ql-editor {
    padding-left: 0px;
    ol,
    ul {
      padding-left: 0px;
      li {
        margin: 10px 0px;
      }
    }
  }
`;
const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
`;
const WrapperRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
const WrapperVideo = styled.div`
  padding: 20px 28px;
  width: 605px;
  height: unset;
  border-radius: 14px;
  background-color: #fff;
  margin-right: 28px;
`;

const LogoUrl = styled.img`
  object-fit: cover;
  max-height: 60px;
  max-width: 150px;
`;

const FlexBetween = styled.div`
  height: 35px;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 518px;
`;
const WrapperButton = styled.div`
  width: 146px;
`;
// const Video = styled.div`
//   margin: auto;
//   width: 528px;
//   height: 286px;
//   border-radius: 11px;
//   border: solid 1px #e1e3ee;
//   background-color: #e1e3ee;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const WrapperNumber = styled.div`
  width: 420px;
  height: 125px;
  border-radius: 14px;
  background-color: #fff;
  padding: 20px;
`;
const TextXl = styled.div`
  margin-top: 9px;
  width: 160px;
  height: 38px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 28px;
  color: #293461;
`;
